$(function () {

    var utensil;
    var geometry;
    var cutter;
    var material;
    var sector;
    var productId;
    var diameter;

    // bootstrap filtri di ricerca
    var r_utensil;
    var r_geometry;
    var r_cutter;
    var r_material;
    var r_sector;
    var r_diametro;

    var selettore_geometries;
    var selettore_cutters;
    var selettore_materials;
    var selettore_sectors;
    var selettore_diametro;
    var bottone_ricerca;

    init();

    $(window).on('popstate', function () {
        init();
    });

    function init() {
        $('#loading').hide();
        $(".rotella").hide();
        $(".rotella1").hide();
        $(".rotella2").hide();
        $(".rotella3").css('visibility', 'hidden');
        $(".rotella4").css('visibility', 'hidden');
        $('#products').hide();
        $('#product').hide();

        $('#alert').hide();

        $('.iconcina_utensil').hide();
        $('.iconcina_geometry').hide();
        $('.iconcina_cutter').hide();
        $('.iconcina_material').hide();
        $('.iconcina_sector').hide();

        utensil = getParameterByName('utensil');
        geometry = getParameterByName('geometry');
        cutter = getParameterByName('cutter');
        material = getParameterByName('material');
        sector = getParameterByName('sector');
        productId = getParameterByName('productId');
        diameter = getParameterByName('diameter');

        // bootstrap filtri di ricerca
        r_utensil = $('input:radio[name=utensil]');
        r_geometry = $('option[name=geometry]');
        r_cutter = $('option[name=cutter]');
        r_material = $('option[name=material]');
        r_sector = $('option[name=sector]');
        r_diametro = $('option[name=diametro]');

        selettore_geometries = $('.selettore_geometries');
        selettore_cutters = $('.selettore_cutters');
        selettore_materials = $('.selettore_materials');
        selettore_sectors = $('.selettore_sectors');
        selettore_diametro = $('.selettore_diametro');
        bottone_ricerca = $('button#search');

        if (r_utensil.is(':checked') === false) {
            r_utensil.filter('[value=' + utensil + ']').prop('checked', true);
        }
        if (r_geometry.is(':selected') === false) {
            r_geometry.filter('[value=' + geometry + ']').prop('selected', true);
        }
        if (r_cutter.is(':selected') === false) {
            r_cutter.filter('[value=' + cutter + ']').prop('selected', true);
        }
        if (r_material.is(':selected') === false) {
            r_material.filter('[value=' + material + ']').prop('selected', true);
        }
        if (r_sector.is(':selected') === false) {
            r_sector.filter('[value=' + sector + ']').prop('selected', true);
        }
        //if (r_diametro.is(':selected') === false) {
        //    r_diametro.filter('[value=' + diameter + ']').prop('selected', true);
        //}

        // bootstrap ricerca
        if (utensil !== null && geometry !== null && cutter !== null && material !== null && productId === null) {
            getProducts(buildSearchString(utensil, geometry, cutter, material, sector, diameter));
        }
        //

        if (productId !== null) {
            getProduct(productId);
        }

    }

    $(document).bind("ajaxSend", function () {
        $("#loading").show();
        $('#products').hide();
        $('#product').hide();
    }).bind("ajaxComplete", function () {
        $("#loading").hide();
        $(".rotella").hide();
        $(".rotella1").hide();
        $(".rotella2").hide();
        $(".rotella3").css('visibility', 'hidden');
        $(".rotella4").css('visibility', 'hidden');
    });

    if (!$("input:radio[name='utensil']").is(':checked')) {
        selettore_geometries.attr("disabled", "disabled");
        selettore_cutters.attr("disabled", "disabled");
        selettore_materials.attr("disabled", "disabled");
        selettore_sectors.attr("disabled", "disabled");
        selettore_diametro.attr("disabled", "disabled");
        bottone_ricerca.attr("disabled", "disabled");
    }

    $('input:radio[name=utensil]').on('change', function () {
        var name = 'utensil';
        var utensil = $('input[name=utensil]:checked').val();
        if (utensil != 0) {
            selettore_geometries.removeAttr("disabled", "disabled");
            $(".rotella").show();
            $.ajax({
                method: 'GET',
                url: products_search_parameters_url + '?from=' + name
                    + '&utensil=' + utensil,
                dataType: 'JSON',
                success: function (data) {
                    replaceOptions(selettore_geometries, data.geometries);
                    //replaceOptions(selettore_cutters, data.cutters);
                    //replaceOptions(selettore_materials, data.materials);
                    //replaceOptions(selettore_sectors, data.sectors);
                    //replaceOptionsSameKey(selettore_diametro, data.diameters);
                }
            });
        }
    });

    selettore_geometries.on('change', function () {
        var name = 'geometry';
        var utensil = $('input[name=utensil]:checked').val();
        var geometry = selettore_geometries.val();
        //selettore_materials.removeAttr("disabled", "disabled");
        //selettore_sectors.removeAttr("disabled", "disabled");
        //selettore_diametro.removeAttr("disabled", "disabled");
        $(".rotella1").show();
        if (geometry != 0) {
            selettore_cutters.removeAttr("disabled", "disabled");
            $.ajax({
                method: 'GET',
                url: products_search_parameters_url + '?from=' + name
                    + '&utensil=' + utensil
                    + '&geometry=' + geometry,
                dataType: 'JSON',
                success: function (data) {
                    replaceOptions(selettore_cutters, data.cutters);
                    //replaceOptions(selettore_materials, data.materials);
                    //replaceOptions(selettore_sectors, data.sectors);
                    //replaceOptionsSameKey(selettore_diametro, data.diameters);
                }
            });
        }
    });

    selettore_cutters.on('change', function () {
        var name = 'cutter';
        var utensil = $('input[name=utensil]:checked').val();
        var geometry = selettore_geometries.val();
        var cutter = selettore_cutters.val();
        $(".rotella2").show();
        if (cutter != 0) {
            selettore_materials.removeAttr("disabled", "disabled");
            $.ajax({
                method: 'GET',
                url: products_search_parameters_url + '?from=' + name
                    + '&utensil=' + utensil
                    + '&geometry=' + geometry
                    + '&cutter=' + cutter,
                dataType: 'JSON',
                success: function (data) {
                    replaceOptions(selettore_materials, data.materials);
                    //replaceOptions(selettore_sectors, data.sectors);
                    //replaceOptionsSameKey(selettore_diametro, data.diameters);
                }
            });
        }
    });

    selettore_materials.on('change', function () {
        // var name = 'material';
        // var utensil = $('input[name=utensil]:checked').val();
        // var geometry = selettore_geometries.val();
        // var cutter = selettore_cutters.val();
        // var material = selettore_materials.val();
        if (material != 0) {
            // selettore_sectors.removeAttr("disabled", "disabled");
            // selettore_diametro.removeAttr("disabled", "disabled");
            // $(".rotella3").css('visibility', 'visible');
            // $(".rotella4").css('visibility', 'visible');
            bottone_ricerca.removeAttr("disabled", "disabled");
            // $.ajax({
            //     method: 'GET',
            //     url: products_search_parameters_url + '?from=' + name
            //         + '&utensil=' + utensil
            //         + '&geometry=' + geometry
            //         + '&cutter=' + cutter
            //         + '&material=' + material,
            //     dataType: 'JSON',
            //     success: function (data) {
            //         if (data.diameters.length === 1) {
            //             alert('Non ci sono utensili per i criteri inseriti');
            //         }
            //         replaceOptions(selettore_sectors, data.sectors);
            //         replaceOptionsSameKey(selettore_diametro, data.diameters);
            //         selettore_diametro.val('Seleziona un diametro');
            //         $(".selettore_diametro option:selected").val('');
            //         if (typeof diameter !== "undefined") {
            //             $('.selettore_diametro').find("option[value='" + diameter + "']").prop('selected', true);
            //         }
            //     }
            // });
        }
    });

    if (typeof diameter !== "undefined") {
        selettore_materials.trigger('change');
    }

    $('button#search').on('click', function () {

        getParameters();

        if (typeof utensil === 'undefined' || typeof geometry === 'undefined') {
            $('#alert').show();
        } else {
            var query = buildSearchString(utensil, geometry, cutter, material, sector, diameter);
            history.pushState('', document.title, location.protocol + '//' + location.host + location.pathname + '?' + query);
            getProducts(query);

            var not_utensil = $('input:not(:checked)').val();
            var not_geometry = $('option:not(:selected)').val();
            var not_cutter = $('option:not(:selected)').val();
            var not_material = $('option:not(:selected)').val();
            var not_sector = $('option:not(:selected)').val();

            $('.iconcina_utensil').hide();
            $('.iconcina_geometry').hide();
            $('.iconcina_cutter').hide();
            $('.iconcina_material').hide();
            $('.iconcina_sector').hide();

            $('.iconcina_utensil').filter('[value=' + utensil + ']').show();
            $('.iconcina_geometry').filter('[value=' + geometry + ']').show();
            $('.iconcina_cutter').filter('[value=' + cutter + ']').show();
            $('.iconcina_material').filter('[value=' + material + ']').show();
            $('.iconcina_sector').filter('[value=' + sector + ']').show();

            $('.iconcina_utensil').filter('[value=' + not_utensil + ']').hide();
            $('.iconcina_geometry').filter('[value=' + not_geometry + ']').hide();
            $('.iconcina_cutter').filter('[value=' + not_cutter + ']').hide();
            $('.iconcina_material').filter('[value=' + not_material + ']').hide();
            $('.iconcina_sector').filter('[value=' + not_sector + ']').hide();

            $('#console_ricerca').show();
            $('.ricerca_avanzata_1').show();
        }
    });

    $('button.back_to_search').on('click', function () {
        window.history.back();
    });

    $('button.back_to_products').on('click', function () {
        window.history.back();
    });

    $(document).on('click', '#back_from_prod', function () {
        window.history.back();
    });

    $(document).on('click', 'button.product-open', function () {
        var id = $(this).attr('data-id');
        getParameters();
        var query = buildSearchString(utensil, geometry, cutter, material, sector) + '&productId=' + id;
        history.pushState('', document.title, location.protocol + '//' + location.host + location.pathname + '?' + query);
        getProduct(id);
    });

    function getProducts(query) {
        $.ajax({
            method: 'GET',
            url: products_search_url + '?' + query,
            success: function (data) {
                $('#products')
                    .show()
                    .find('tbody').html(data);

                $('#product').hide();
                $('#console_ricerca').show();
                $('.ricerca_avanzata_1').show();

                $('html, body').animate({
                    scrollTop: $("#loading").offset().top
                }, 500);
            }
        });
    }

    function getProduct(id) {
        $.ajax({
            method: 'GET',
            url: product + '/' + id,
            success: function (data) {
                if (data === 'no_market') {
                    window.location.replace("/");
                } else {
                    $('#product')
                        .show()
                        .find('> div').html(data);

                    $('#products').hide();
                    $('#console_ricerca').hide();
                    $('.ricerca_avanzata_1').hide();

                    $('html, body').animate({
                        scrollTop: $("#header_ricerca_veloce").offset().top
                    }, 500);
                }
            }
        });
    }

    function buildSearchString(utensil, geometry, cutter, material, sector, diameter) {
        var param = {
            utensil: utensil,
            geometry: geometry
        };
        if (cutter != 0) {
            param["cutter"] = cutter;
        }
        if (material != 0) {
            param["material"] = material;
        }
        if (sector != 0) {
            param["sector"] = sector;
        }
        if (diameter != 0) {
            param["diameter"] = diameter;
        }
        return $.param(param);
    }

    function getParameters() {
        utensil = $('input[name=utensil]:checked').val();
        geometry = selettore_geometries.val();
        cutter = selettore_cutters.val();
        material = selettore_materials.val();
        sector = selettore_sectors.val();
        diameter = selettore_diametro.val();
    }

    $('.table-hover > tbody > tr').mouseenter(function () {
        $('button.product-open', this).css("color", "#F97B00");
    }).mouseleave(function () {
        $('button.product-open', this).css("color", "#333");
    });


});
