function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function getUrlVars() {
    if (window.location.href.indexOf('?') == -1) {
        return null;
    }
    var vars = [];
    var hash = [];
    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for (var i = 0; i < hashes.length; i++) {
        hash = hashes[i].split('=');
        var value = hash[1];
        if (new RegExp(',').test(value)) {
            value = hash[1].split(',');
        }
        vars[hash[0]] = value;
    }
    return vars;
}

function replaceOptions(el, options) {
    el.empty();
    $.each(options, function (key, value) {
        el.append($("<option></option>")
            .attr("value", key).text(value)
        );
    });
}

function replaceOptionsFloat(el, options, float= true) {
    el.empty();
    $.each(options, function (key, value) {
        el.append($("<option></option>")
            .attr("value", key).text(value)
        );
    });
    var my_options = el.find('option');
    var sliced = my_options.slice(1);
    if (float){
        sliced.sort(function (a, b) {
            let aFloat = parseFloat(a.text.replace(',', '.'));
            let bFloat = parseFloat(b.text.replace(',', '.'));
            if (aFloat > bFloat) return 1;
            if (aFloat < bFloat) return -1;
            return 0
        })
    }else{
        sliced.sort(function (a, b) {
            if (a.text > b.text) return 1;
            if (a.text < b.text) return -1;
            return 0
        })
    }

    el.empty().append(my_options[0]).append(sliced);
    el.val('0');
}

function replaceOptionsSameKey(el, options) {
    el.empty();
    $.each(options, function (key, value) {
        el.append($("<option></option>")
            .attr("value", value).text(value)
        );
    });
    var selOptions = el.find('option');
    selOptions.sort(function (a, b) {
        return parseFloat($(a).text()) > parseFloat($(b).text()) ? 1 : -1;
    });
    el.html(selOptions);
}
